import './App.css';
import Navigationbar from './navbar/Navbar';
import {BrowserRouter} from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import backgroundImage from './wallp2.png';
// import video2 from './whitesmoke.mov';
import video1 from './second-sound.mp4';
function App() {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true); 
  useEffect(() => {
    const playVideo = async () => {
      try {
        if (videoRef.current) {
          videoRef.current.muted = isMuted;
          videoRef.current.volume = 0.12;
          await videoRef.current.play();
        }
      } catch (error) {
        console.error("Autoplay was prevented:", error);
      }
    };
    playVideo();
  }, []);

  return (
    <BrowserRouter>
    <div className='App'>
        {/* <video className="background-video" src={video1} autoPlay loop = {true} /> */}
        <video 
      ref={videoRef}
      className="background-video"
      src={video1}
      autoPlay
      loop = {true}
      muted = {isMuted}
      playsInline
    />
    <source src={video1} type="video/mp4" />
    {/* <video 
      ref={videoRef}
      className="background-video"
      src={video2}
      autoPlay
      loop
      muted={false}
      playsInline
    /> */}
    <button 
          className="volume-button" 
          onClick={() => setIsMuted(prevMuted => !prevMuted)} 
          style={{
            position: 'absolute',
            top: '60px',
            right: '20px',
            background: 'rgba(255, 255, 255, 0.7)',
            border: 'none',
            borderRadius: '6px',
            padding: '5px',
            cursor: 'pointer'
          }}
        >
          {isMuted ? <FaVolumeUp color = "green" size={35}/> : <FaVolumeMute color = "red" size={35}/>}
        </button>
    <Navigationbar />
    
   </div>
   </BrowserRouter>
  );
}

export default App;
